import React from 'react';
import capitalize from 'lodash/capitalize';

const Filters = ({ type, setType, search, setSearch, types }) => {
    return <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="mt-5 md:mt-0 md:col-span-3">
                <div className="shadow overflow-hidden sm:rounded-md">
                    <div className="px-4 py-5 bg-white sm:p-6">
                        <div className="grid grid-cols-6 gap-6">
                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="country"
                                       className="block text-sm font-medium leading-5 text-gray-700">
                                    Filter by name</label>
                                <input
                                    id="country"
                                    className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                    type="search"
                                    placeholder={"Type December"}
                                    aria-label="Class name"
                                    value={search}
                                    autoFocus
                                    onChange={e => {
                                        setSearch(e.target.value)
                                    }}
                                />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="status"
                                       className="block text-sm font-medium leading-5 text-gray-700">Search by
                                    Type</label>
                                <select id="status"
                                        className="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                        value={type}
                                        onChange={e => {
                                            setType(e.target.value !== '' ? e.target.value : '')
                                        }}>
                                    <option value={'all'}>{'All'}</option>
                                    {types.map(it =>
                                        <option key={it} value={it}>{capitalize(it)}</option>
                                    )}
                                </select>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>;
}
export default Filters
