import React, { useState, useEffect, useMemo, useContext } from 'react'
import placeholder from '../static/tools/11.png'
import Fuse from 'fuse.js'
import { Transition } from '@headlessui/react'
import Filters from '../components/Filters'
import Pagination from '../components/Pagination'
import { AppContext } from '../context'
import { useStripeCheckout } from '../hooks/useStripeCheckout'
import Layout from '../components/layout'

const CheckoutButton = ({ product, text = 'Continue to payment' }) => {
  const { loading, redirectToCheckout } = useStripeCheckout(product)

  return <span className="inline-flex rounded-md shadow-sm items-center">
             <button type="button"
                     onClick={redirectToCheckout}
                     className="inline-flex items-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-700 hover:bg-blue-500 focus:outline-none focus:border-blue-800 focus:ring-indigo active:bg-blue-800 transition ease-in-out duration-150">
                 {loading ? <div className="flex flex-row">
                   <svg className="animate-spin w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                        fill="currentColor">
                     <path fillRule="evenodd"
                           d="M9.504 1.132a1 1 0 01.992 0l1.75 1a1 1 0 11-.992 1.736L10 3.152l-1.254.716a1 1 0 11-.992-1.736l1.75-1zM5.618 4.504a1 1 0 01-.372 1.364L5.016 6l.23.132a1 1 0 11-.992 1.736L4 7.723V8a1 1 0 01-2 0V6a.996.996 0 01.52-.878l1.734-.99a1 1 0 011.364.372zm8.764 0a1 1 0 011.364-.372l1.733.99A1.002 1.002 0 0118 6v2a1 1 0 11-2 0v-.277l-.254.145a1 1 0 11-.992-1.736l.23-.132-.23-.132a1 1 0 01-.372-1.364zm-7 4a1 1 0 011.364-.372L10 8.848l1.254-.716a1 1 0 11.992 1.736L11 10.58V12a1 1 0 11-2 0v-1.42l-1.246-.712a1 1 0 01-.372-1.364zM3 11a1 1 0 011 1v1.42l1.246.712a1 1 0 11-.992 1.736l-1.75-1A1 1 0 012 14v-2a1 1 0 011-1zm14 0a1 1 0 011 1v2a1 1 0 01-.504.868l-1.75 1a1 1 0 11-.992-1.736L16 13.42V12a1 1 0 011-1zm-9.618 5.504a1 1 0 011.364-.372l.254.145V16a1 1 0 112 0v.277l.254-.145a1 1 0 11.992 1.736l-1.735.992a.995.995 0 01-1.022 0l-1.735-.992a1 1 0 01-.372-1.364z"
                           clipRule="evenodd" />
                   </svg>
                   &nbsp;
                   Creating Order</div> : text}
            </button>
           </span>
}

const DEFAULT_PAGE_LENGTH = 12

function scrollToTop () {
  (function smoothscroll () {
    const currentScroll = document.documentElement.scrollTop || document.body.scrollTop
    if (currentScroll > 0) {
      window.requestAnimationFrame(smoothscroll)
      window.scrollTo(0, currentScroll - (currentScroll / 5))
    }
  })()
}

const types = {
  brief: 'Client Brief',
  class: 'Class',
  project: 'Project',
  'yearly-brief': 'Client Brief - One Year Subscription',
  'yearly-class': 'Class - One Year Subscription',
  'yearly-project': 'Project - One Year Subscription',
}

const ProductCard = ({ product }) =>
  <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
    <div className="flex-shrink-0">
      <img className="h-48 w-full object-cover"
           src={product.images.length > 0 ? product.images[0] : placeholder}
           alt="" />
    </div>
    <div className="flex-1 bg-white p-6 flex flex-col justify-between">
      <div className="flex-1">
        <p className="self-center text-sm leading-5 font-medium text-indigo-600">
          {types[product.metadata.type]}
        </p>
        <div className="block self-center ">
          <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900 text-center">
            {product.name}
          </h3>
          <p className="mt-3 text-base leading-6 text-gray-500 text-center">
            {product.description}
          </p>
        </div>
      </div>
      <div className="text-center font-bold text-xl">
        €{product.prices && product.prices[0].amount / 100}
      </div>
      <div className="mt-6 flex items-center self-center ">
        <CheckoutButton product={product} />
      </div>
    </div>
  </div>

const Message = ({ message }) => {
  const [show, setShow] = useState(!!message)
  return <div
    className="fixed inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end">
    <Transition
      show={show}
      enter="transition ease-out duration-300"
      enterFrom="transform opacity-0 -translate-y-2"
      enterTo="transform opacity-100 translate-y-0"
      leave="transition ease-in duration-100"
      leaveFrom="transform opacity-100 translate-y-2"
      leaveTo="transform opacity-0 -translate-y-2"
    >
      {(ref) => <div ref={ref} className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto">
        <div className="rounded-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div className="p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0">
                <svg className="h-6 w-6 text-green-400" xmlns="http://www.w3.org/2000/svg"
                     fill="none"
                     viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <div className="ml-3 w-0 flex-1 pt-0.5">
                <p className="text-sm leading-5 font-medium text-gray-900">
                  {message}
                </p>
              </div>
              <div className="ml-4 flex-shrink-0 flex">
                <button
                  onClick={() => setShow(false)}
                  className="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150">
                  <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                       viewBox="0 0 20 20"
                       fill="currentColor">
                    <path fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </Transition>
  </div>
}

const possibleItemTypes = ['brief', 'class', 'project', 'upgrade']
const Subscribe = ({ location }) => {
  const [message, setMessage] = useState('')
  const { state: contextState } = useContext(AppContext)
  const [search, setSearch] = useState(location.item ? location.item : '')
  const [type, setType] = useState(location?.state?.type ? location?.state?.type : 'all')
  const [state, setState] = useState({
    currentPage: 1
  })
  const options = {
    keys: ['name', 'description'],
    threshold: 0.05
  }
  const products = contextState ? contextState.products : []
  const list = products
    .filter(it => {
        const small_items = type === 'small_items' && (it.metadata.type === 'brief' || it.metadata.type === 'project')
        const full_briefs = type === 'brief' && it.metadata.type === 'yearly_brief'
        const full_classes = type === 'class' && it.metadata.type === 'yearly_class'
        const full_projects = type === 'project' && it.metadata.type === 'yearly_project'
        return it.metadata.type === type
          || type === 'all'
          || small_items
          || full_briefs
          || full_classes
          || full_projects
      }
    )
  const fuse = new Fuse(list, options)

  const result = (search ? fuse.search(search).map(it => it.item) : list)
    .sort((a, b) => {
      let firstDate = Date.parse(a.metadata.end_date)
      let secondDate = Date.parse(b.metadata.end_date)
      if (isNaN(firstDate)) firstDate = Number.MAX_VALUE
      if (isNaN(secondDate)) secondDate = Number.MAX_VALUE
      return firstDate - secondDate
    })
  const indexOfLastItem = useMemo(() => state.currentPage * DEFAULT_PAGE_LENGTH, [state.currentPage])
  const indexOfFirstItem = useMemo(() => indexOfLastItem - DEFAULT_PAGE_LENGTH, [indexOfLastItem])
  const currentItems = useMemo(() => result.slice(indexOfFirstItem, indexOfLastItem), [result, indexOfLastItem, indexOfFirstItem])
  const handlePageClick = (page) => {
    scrollToTop()
    setState({ ...state, currentPage: page })
  }
  const handleNextClick = () => {
    scrollToTop()
    setState({ ...state, currentPage: state.currentPage + 1 })
  }
  const handlePreviousClick = () => {
    scrollToTop()
    setState({ ...state, currentPage: state.currentPage - 1 })
  }

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)

    if (query.get('success')) {
      setMessage('Order placed! You will receive an email confirmation.')
    }

    if (query.get('canceled')) {
      setMessage(
        'Order canceled -- continue to shop around and checkout when you\'re ready.'
      )
    }

  }, [])
  return <Layout>
    <div>
      <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        {message && <Message message={message} />}
        <div className="absolute inset-0">
          <div className="bg-white h-1/3 sm:h-2/3"></div>
        </div>
        <div className="relative max-w-7xl mx-auto">
          <div className="text-center">
            <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
              Purchase
            </h2>
            <p className="mt-3 max-w-2xl mx-auto text-xl leading-7 text-gray-500 sm:mt-4">
              Have a look at everything that is available. At this time it's not possible to buy multiple
              items in a single purchase.
            </p>
          </div>
          <div className="mx-auto">
          <Filters setSearch={setSearch} search={search} setType={setType} type={type}
                   types={possibleItemTypes} />
          </div>
          <div className="mt-12 grid gap-5 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none">
            {currentItems.map(it => <ProductCard product={it} key={it.id} />)}
          </div>
          {products.length > 0 && result.length > DEFAULT_PAGE_LENGTH ? <Pagination
              onPageClick={handlePageClick}
              currentPage={state.currentPage} length={result.length}
              onNext={handleNextClick}
              onPrevious={handlePreviousClick} />
            : null
          }
        </div>
      </div>
    </div>
  </Layout>
}

export default Subscribe
