import React from 'react';

const Pagination = ({ onNext, onPrevious, currentPage, onPageClick, length, itemPerPage = 10 }) => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(length / itemPerPage); i++) {
        pageNumbers.push(
            <button type="button"
                    key={i}
                    onClick={() => onPageClick(i)}
                    className={`${currentPage === i ? 'text-gray-800 underline' : ' text-gray-800'}  ${currentPage !== i ? 'hover:text-gray-500' : null} 
                                -ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium 
                                focus:z-10 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-100 active:text-gray-700
                                transition ease-in-out duration-150`}>
                {i}
            </button>);
    }

    const firstPage = currentPage === 1;
    const lastPage = (length - (currentPage * itemPerPage) + itemPerPage) < itemPerPage; // long day
    return <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
        <div className="flex-1 flex justify-between sm:hidden">
            <button
                disabled={firstPage} onClick={onPrevious}
                className={`${firstPage ? 'bg-gray-200' : 'bg-white'} relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5
                    font-medium rounded-md text-gray-700 hover:text-gray-500 focus:outline-none
                    focus:ring-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150`}>
                Previous
            </button>
            <button
                disabled={lastPage} onClick={onNext}
                className={`${lastPage ? 'bg-gray-200' : 'bg-white'} ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md 
                    text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-blue 
                    focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150`}>
                Next
            </button>
        </div>
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
                <p className="text-sm leading-5 text-gray-700">
                    Showing&nbsp;
                    <span className="font-medium">{currentPage * itemPerPage - 10 + 1}&nbsp;</span>
                    to&nbsp;
                    <span
                        className="font-medium">{lastPage ? length : currentPage * itemPerPage - 10 + itemPerPage}&nbsp;</span>
                    of&nbsp;
                    <span className="font-medium">{length}&nbsp;</span>
                    results
                </p>
            </div>
            <div>
                <nav className="relative z-0 inline-flex shadow-sm">
                    <button type="button"
                            disabled={firstPage} onClick={onPrevious}
                            className={`${firstPage ? 'cursor-not-allowed bg-gray-200' : 'bg-white hover:text-gray-400'} relative inline-flex items-center
                            px-2 py-2 rounded-l-md border border-gray-300 text-sm leading-5  font-medium text-gray-500 focus:z-10
                            focus:outline-none focus:border-blue-300 focus:ring-blue 
                            active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150`}
                            aria-label="Previous">
                        <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                            <path fillRule="evenodd"
                                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                  clipRule="evenodd" />
                        </svg>
                    </button>
                    {pageNumbers}
                    <button type="button"
                            disabled={lastPage} onClick={onNext}
                            className={`${lastPage ? 'bg-gray-200 cursor-not-allowed' : 'bg-white hover:text-gray-400'} -ml-px relative inline-flex items-center px-2 py-2
                            rounded-r-md border border-gray-300 text-sm leading-5 font-medium text-gray-500 focus:z-10
                            focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-100 active:text-gray-500 transition
                            ease-in-out duration-150`}
                            aria-label="Next">
                        <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                            <path fillRule="evenodd"
                                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                  clipRule="evenodd" />
                        </svg>
                    </button>
                </nav>
            </div>
        </div>
    </div>

}

export default Pagination
